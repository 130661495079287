@import "~antd/dist/antd.css";

.fjPCzc{
    opacity: 0.5
}
.MuiSvgIcon-root{
    cursor: pointer;
}
.readNotificationTitle{
    color:lightgray;
  
}
.unReadNotificationTitle{
    width:"200px" !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* color: black; */
}
.snackbarBox{
    background-color: #2e7d32!important;
    color: white !important;
    font-size: 16px !important;

}
.MuiAlert-icon{
    color: white !important;

}
/* .bg-gray ~ .parent{
    background: lightgray
} */
.fs-13{
    font-size: 13px;
}
.mb-0{
    margin-bottom: 0px;
}
.h-200{
    height: 200px;
}
.w-200{
    width: 200px;
}
.d-flex{
    display: flex;
}
.pl-23{
    padding-left: 23px;
}
.cursorPointer{
    cursor: pointer;
}